import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Vehicle } from '../../component/entities/vehicle.entity';
import { Driver } from '../../component/entities/driver.entity';
const imgLogo = require('../../img/driver8-logo.png');

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 50,
        lineHeight: 1.5
    },
    logo: {
        backgroundColor: '#2c5b65',
        width: 150,
        height: 150,
        margin: '0 auto',
        marginBottom: 20,
        padding: 20,
        borderRadius: 8,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 10,
    },
    break: {
        marginBottom: 10,
    },
    center: {
        textAlign: 'center',
    },
    section: {
        margin: 0,
        padding: 0,
        flexGrow: 1
    },
    party: {
        fontWeight: 1000
    },
    sectionHeading: {
        textTransform: 'uppercase',
        marginBottom: 10,
        marginTop: 30,
        fontSize: 18,
    },
    signature: {
        marginTop: 100,
        padding: 50,
    }
});

interface Props {
    driver: Driver;
    vehicle: Vehicle;
    amount: number;
    signature?: string;
}

export const Agreement = ({ driver, vehicle, amount, signature }: Props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Image src={imgLogo} style={styles.logo} />
                <Text style={styles.title}>TAXICAB HIRE AGREEMENT</Text>
            </View>
            <View style={[styles.section, styles.center]}>
                <Text>The following agreement is between:</Text>
                <View style={styles.break} />
                <Text style={styles.party}>Muhammed Al-Mumin (on behalf of Driver 8 Ltd)</Text>
                <Text>Of Driver 8 Ltd 17 Hanover Square, London, W1S 1BN</Text>
                <View style={styles.break} />
                <Text>and</Text>
                <View style={styles.break} />
                <Text>{driver.firstName} {driver.lastName}</Text>
                <Text>Of {driver.address?.text}</Text>
                <View style={styles.break} />
                <Text>License Number: {driver.licenseNumber}</Text>
                <Text>License Expiry Date: {driver.licenseExpiryDate}</Text>
                <Text>D.O.B. {driver.dateOfBirth}</Text>

                <View style={styles.break} />
                <View style={styles.break} />
                <Text>This agreement commenced on the day and date it was signed.</Text>
                <View style={styles.break} />
                <View style={styles.break} />
            </View>
            <View style={styles.section}>
                <Text>THE VEHICLE: {vehicle.year} {vehicle.make} {vehicle.model}</Text>
                <Text>REGISTRATION NUMBER: {vehicle.plate}</Text>
                <Text>RENT: £ {(amount / 100).toFixed(0)} PER WEEK</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.sectionHeading}>1. Rental period</Text>
                <Text>The conditions of this Agreement apply to any vehicles, including replacement vehicles, rented from Driver 8 Ltd. Renter will rent the vehicle for the rental period shown on the Agreement, which in this case is 12 months from the date the agreement was signed. Driver 8 Ltd may agree to extend this rental period at the end of the hire period but must be given 30 days' notice in advance.</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>2. Renter's responsibilities </Text>
                <Text>(a) Renter must look after the vehicle and keys. Renter must always lock the vehicle when he is not using it, and he will incur a charge for replacement of lost keys. Renter must use any security device fitted to or supplied with the vehicle, regardless of whether such a device came with the vehicle or is added at some point during the rental period by Driver 8 Ltd. (b)Renter must make sure that he uses the correct fuel. (c) Renter is responsible for any damage to the roof or upper part of the vehicle caused by hitting low objects, such as low car park roofs or branches; or damage caused to the sides of the cab by entering a passage too narrow for the vehicle such as a narrow alleyway. (d) Renter must not sell, rent, or dispose of the vehicle or any of its parts. Renter must not give anyone any legal rights over the vehicle. (e) Renter must not let anyone work on the vehicle without written permission. (f) Renter must let Driver 8 Ltd know as soon as he becomes aware of any defect(s) of any type in the vehicle. (g) ‘Damage to the vehicle' includes glass and tyre damage. (h) Renter will have to pay for repairs if the vehicle needs more than standard valeting or minor repairs, i.e., minor scratches to interior trim or if the vehicle has been significantly damaged (beyond expected wear and tear) either inside or outside. (i)Renter must keep cab available at short notice for any routine checks and repairs needed, for example MOT or six-monthly licencing checks. If Renter does not make the cab available for such an appointment and as a consequence Driver 8 Ltd incurs the loss of a fee or additional charges, the Renter is liable to pay these. (j)Renter must return any property left in the cab by a passenger to the nearest police station.</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>3.	Driver 8 Ltd.'s responsibilities </Text>
                <Text>(a) Driver 8 Ltd must collect the rental charge from the renter on Friday as agreed. If unable to collect on Friday, they must let the renter know with at least two hours' notice and let him know a new time for collection. (b) Driver 8 Ltd is responsible for routine repairs and maintenance of the cab that are due to an expected level of wear and tear. (c) Driver 8 Ltd is responsible for licencing, insurance, and taxation of the vehicle. (d) Driver 8 Ltd is only responsible for loss or damage to property left in the vehicle if the loss or damage results from their negligence.</Text>
            </View>

            <View style={styles.break} />
            <View style={styles.break} />
            <View style={styles.break} />
            <View style={styles.section}>
                <Text style={styles.sectionHeading}>4. Conditions for using the vehicle</Text>
                <Text>The vehicle must only be driven by the person named in the Agreement. Renter must not:
                    • Use the vehicle for any illegal purpose.

                    • Use the vehicle off-road, or for racing, pacemaking, testing the vehicle's reliability and speed or for teaching someone to drive.
                    • Use the vehicle under the influence of any illegal substance.
                    • Carry a number of passengers (more than 5) and/or baggage which would cause the vehicle to be overloaded or in breach of the conditions set by TPH; or for a purpose which requires an Operator's Licence other than that which the Renter has.
                    • Drive the vehicle outside the United Kingdom unless Driver 8 Ltd has given
                    Renter written permission.
                    •Keep the vehicle parked overnight outside an address other than those written at the top of this agreement without the permission of Driver 8 Ltd.</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>5. Charges</Text>
                <Text>Renter will pay the following charges:
                    (a) The rental charge of per week in full in cash/bank on a Friday
                    (b) Any charge for serious loss or damage resulting from non-compliance with Clause 2.
                    (c) Any fees or charges resulting from non-compliance with sub-clause (i) in Clause 2.
                    (d) On demand, all fines and court costs for parking, traffic, or other offences (including any costs which arise if the vehicle is clamped). Renter must inform Driver 8 Ltd of any such charges and pay the appropriate authority any fines and costs without delay. If he does not, Renter will be responsible to pay Driver 8 Ltd.'s reasonable administration charges which arise when they deal with these matters (d) On demand, any charges made by Customs and Excise, The Police, or any other authority as a result of seizure of the vehicle by them.</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>6. Risk protection</Text>
                <Text>The rental charge under the Agreement includes cab insurance. This provides comprehensive cover for claims made if Renter injures or kills anybody, and at least statutory cover for damage to property. Renter is bound by and agrees to the terms and conditions of the insurance policy which is available on request. </Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>7. What to do in case of accident or theft</Text>
                <Text>If Renter has an accident, he must not admit responsibility. He should get the names and addresses of everyone involved, including witnesses. Renter should also: make the vehicle secure; tell the police immediately if anyone is injured or there is a disagreement as to the facts; and call Driver 8 Ltd straight away. </Text>

            </View>
            <View style={styles.section}>
                <Text style={styles.sectionHeading}>8. Information and Data Protection</Text>
                <Text>Driver 8 Ltd will maintain the personal information on the Agreement as part of their records to assist them to maintain and improve their administration and management of their cab rental business. Driver 8 Ltd reserves the right to release this information to third parties providing it is for a purpose for which this is necessary: for example, renewing insurance or providing more detail to the insurance company at their request. Renter is welcome to see the information held about him at any time and to make any necessary amendments to keep the information up to date; this is all in compliance with The Data Protection Act 1998.</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>9. Ending the Agreement</Text>
                <Text>Driver 8 Ltd will end this Agreement straight away if they find out that Renter's goods have been taken away from him to pay off his debts, or if a receiving order has been made against him. Driver 8 Ltd will also end this Agreement if Renter does not meet any of the conditions of the Agreement. If Driver 8 Ltd ends the Agreement it will not affect their right to receive any money they are owed under the conditions of the Agreement, nor their right to pursue action in the courts to retrieve this money. Driver 8 Ltd can also claim extra reasonable costs from Renter if he does not meet any of the conditions of the Agreement. In the event of material breach by Renter, Driver 8 Ltd can repossess the vehicle and charge Renter any reasonable costs of repossession.</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeading}>10. Governing law</Text>
                <Text>The Agreement is governed by the laws of England. Any dispute may be submitted to the non-exclusive jurisdiction of the English courts. If any provision of this Agreement is or becomes invalid or unenforceable the remaining provisions shall not be affected. </Text>
            </View>


            <View style={styles.signature}>
                { signature && <Image source={signature} style={{ width: 150, marginBottom: -20, marginLeft: 100 }} /> }
                <Text>Signed: _______________________</Text>
                <Text>Name: {driver.firstName} {driver.lastName}</Text>
                <Text>Date: {new Date().toLocaleDateString()}</Text>
                <View style={styles.break} />
                <View style={styles.break} />
                <View style={styles.break} />
                <View style={styles.break} />
                <View style={styles.break} />
                <View style={styles.break} />
                <View style={styles.break} />
                <View style={styles.break} />
                <Text>Signed: _______________________</Text>
                <Text>Name: Muhammad Al Mumin (On Behalf of Driver 8 Ltd)</Text>
                <Text>Date: {new Date().toLocaleDateString()}</Text>
            </View>

        </Page>
    </Document>
);