import React, { useEffect, useRef, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Driver, Gender } from '../../component/entities/driver.entity';
import { DropzoneFile, FormUpload } from '../../component/form/input/upload.component';
import { useLocation } from 'react-router-dom';
import Axios, { AxiosError } from 'axios';
import { InputMask } from '@react-input/mask';

const imgLogo = require('../../img/driver8-logo.svg');

enum Step {
  Loading,
  Driver,
  Vehicle,
  Submitting,
  Uploading,
  Submitted,

  ErrInvalidCode,
  ErrNetworkDown,
  ErrServerError,
  ErrGeneral,
}

enum VehicleOwnership {
  Driver8 = 'driver8',
  Own = 'own',
}

enum VehicleType {
  BlackCab = 'black-cab',
  PrivateHireVehicle = 'private-hire-vehicle',
}

enum VehicleDocument {
  LogBook = 'Log Book',
  CabLicense = 'Cab License',
  Insurance = 'Insurance',
  MoT = 'MoT',
  Registration = 'Registration',
}

const VehicleDocuments = {
  [VehicleOwnership.Driver8]: {
    [VehicleType.BlackCab]: [
      VehicleDocument.LogBook,
      VehicleDocument.CabLicense,
      VehicleDocument.Insurance,
      VehicleDocument.MoT,
    ],
    [VehicleType.PrivateHireVehicle]: [
      VehicleDocument.LogBook,
      VehicleDocument.CabLicense,
      VehicleDocument.Insurance,
      VehicleDocument.MoT,
    ]
  },
  [VehicleOwnership.Own]: {
    [VehicleType.BlackCab]: [
      VehicleDocument.Registration,
    ],
    [VehicleType.PrivateHireVehicle]: [
      VehicleDocument.LogBook,
      VehicleDocument.CabLicense,
      VehicleDocument.Insurance,
      VehicleDocument.MoT,
    ]
  },
}

const BASE_URL = process.env.REACT_APP_API_URL;

export function PageRegister() {

  const location = useLocation();

  const [code, setCode] = useState<string | null>(null);
  const [step, setStep] = useState<Step>(Step.Loading);

  const [error, setError] = useState<string | null>(null);

  const [driver, setDriver] = useState<Driver>();
  const [phone, setPhone] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [gender, setGender] = useState<Gender>(Gender.Male);
  const [dob, setDob] = useState<string>();

  const [licenseNumber, setLicenseNumber] = useState<string>();
  const [badgeNumber, setBadgeNumber] = useState<string>();
  const [licenseExpiration, setLicenseExpiration] = useState<string>();

  const [bankAccountNumber, setBankAccountNumber] = useState<string>();
  const [bankAccountName, setBankAccountName] = useState<string>();
  const [bankSortCode, setBankSortCode] = useState<string>();

  const [driverPassportFiles, setDriverPassportFiles] = useState<DropzoneFile[]>([]);
  const [driverLicenseFrontFiles, setDriverLicenseFrontFiles] = useState<DropzoneFile[]>([]);
  const [driverLicenseBackFiles, setDriverLicenseBackFiles] = useState<DropzoneFile[]>([]);
  const [driverBadgeFiles, setDriverBadgeFiles] = useState<DropzoneFile[]>([]);

  const [vehicleYear, setVehicleYear] = useState<number>();
  const [vehicleMake, setVehicleMake] = useState<string>();
  const [vehicleModel, setVehicleModel] = useState<string>();
  const [vehicleColor, setVehicleColor] = useState<string>();
  const [vehicleVin, setVehicleVin] = useState<string>();
  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState<string>();
  const [vehiclePlateNumber, setVehiclePlateNumber] = useState<string>();
  const [vehiclePlateExpiration, setVehiclePlateExpiration] = useState<string>();
  const [vehicleOwnership, setVehicleOwnership] = useState<VehicleOwnership | null>(null);
  const [vehicleType, setVehicleType] = useState<VehicleType | null>(null);

  const topRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const urlParams = parseUrlSearchParams(location.search);
    if (!urlParams.code || !isCodeFormatCorrect(urlParams.code)) {
      // setStep(Step.ErrInvalidCode);
      setStep(Step.Driver);
      return;
    }
    
    setCode(urlParams.code);
    loadDetails(urlParams.code);

  }, []);

  useEffect(() => {
    if (!driver) {
      return;
    }

    if (driver.licenseNumber) {
      const split = driver.licenseNumber.split('|');
      setLicenseNumber(split[0]);
      setBadgeNumber(split[1]);
    }
    if (driver.phone) {
      if (driver.phone.substr(0, 1) === '+') {
        setPhone(driver.phone);
      } else {
        setPhone(`+${driver.phone}`);
      }
    }
    setFirstName(driver.firstName);
    setLastName(driver.lastName);
    setGender(driver.gender);
    setDob(driver.dateOfBirth?.toLocaleString());
    setLicenseExpiration(driver.licenseExpiryDate?.toLocaleString());
    setBankAccountName(driver.bankAccountTitle);
    setBankAccountNumber(driver.bankAccountNumber);
    setBankSortCode(driver.bankAccountRouting);

    setStep(Step.Driver);
  }, [driver])

  async function loadDetails(code: string) {
    try {
      const response = await Axios.get(`${BASE_URL}/driver/enroll/${code}`);
      setDriver(response.data);
    } catch(err: AxiosError | any) {
      if (err.response?.status === 400) {
        setError(err.response.data.message);
        setStep(Step.ErrGeneral);
      } else if (err.response?.status === 404) {
        setStep(Step.ErrInvalidCode);
      } else if (err.response?.status > 400) {
        setStep(Step.ErrServerError);
      } else if (err.code === 'ECONNABORTED') {
        setStep(Step.ErrNetworkDown);
      } else {
        setStep(Step.ErrNetworkDown);
      }
    }
  }

  async function saveDriver() {
    let _licenseNumber = licenseNumber;
    let _vehicleVin = vehicleVin;

    if (badgeNumber) {
      _licenseNumber = `${licenseNumber || ''}|${badgeNumber}`;
    }
    if (vehiclePlateNumber) {
      _vehicleVin = `${vehicleVin || ''}|${vehiclePlateNumber}`;
    }

    const driver = {
      phone,
      firstName,
      lastName,
      gender,
      dateOfBirth: dob,
      licenseNumber: _licenseNumber,
      licenseExpiryDate: licenseExpiration,
      bankAccountNumber,
      bankAccountTitle: bankAccountName,
      bankAccountRouting: bankSortCode?.split('-').join(''),
    }

    const vehicle = vehicleRegistrationNumber ? {
      vin: _vehicleVin,
      plate: vehicleRegistrationNumber,
      registrationExpiryDate: vehiclePlateExpiration,
      year: vehicleYear,
      make: vehicleMake,
      model: vehicleModel,
      color: vehicleColor,
      numPassengers: 4,
      numLuggage: 2,
      operator: '6f8adfe6-0657-4f54-be95-f7ac8f43530c',
      services: ['ef534735-8d64-4809-a348-400e87d35915', 'b567500b-3c18-468f-9ff1-2c64f7fdefd3'],
      fleets: ['62c16214-15cf-456c-b1cd-80e4cbc15dc4'],
      networks: ['617ed4b0-c56c-4b2e-ae7b-086747bd6021'],
      owner: 'cd52b6fa-8cb3-4a4d-acad-a9dc5ad31c51',
      ownership: 'owned',
    } : null;

    await Axios.post(`${BASE_URL}/driver/enroll`, { code, driver, vehicle });
  }

  async function uploadDocuments() {
    if (!driver) {
      return;
    }
    const data = new FormData();
    data.append('id', driver.id as string);
    for (const driverPassportFile of driverPassportFiles) {
      data.append('passport', driverPassportFile);
    }
    for (const driverLicenseFrontFile of driverLicenseFrontFiles) {
      data.append('licenseFront', driverLicenseFrontFile);
    }
    for (const driverLicenseBackFile of driverLicenseBackFiles) {
      data.append('licenseBack', driverLicenseBackFile);
    }
    for (const driverBadgeFile of driverBadgeFiles) {
      data.append('badgeNumber', driverBadgeFile);
    }

    await Axios.post(`${BASE_URL}/driver/register/upload`, data);
  }

  function parseUrlSearchParams(params: string): { code: string } {
    const urlParams = new URLSearchParams(params);
    return {
      code: urlParams.get('code') || '',
    };
  }

  // Code format needs to be in the format AAA-NNNN
  function isCodeFormatCorrect(code: string): boolean {
    return /^([A-Z]{2})-([0-9]{4})-([0-9]{2})$/.test(code);
  }

  function proceedToVehicle(e: any) {
    setStep(Step.Vehicle);
    e.preventDefault();
    scrollToTop();
  }

  async function completeEnrollment(e: any) {
    setStep(Step.Submitting);
    e.preventDefault();
    scrollToTop();

    try {
      await saveDriver()
      setStep(Step.Uploading);

      await uploadDocuments();
      setStep(Step.Submitted);
    } catch(err: AxiosError | any) {
      if (err.response?.status === 400) {
        setError(err.response.data.message);
        setStep(Step.ErrGeneral);
      } else if (err.response?.status === 404) {
        setStep(Step.ErrInvalidCode);
      } else if (err.response?.status > 400) {
        setStep(Step.ErrServerError);
      } else if (err.code === 'ECONNABORTED') {
        setStep(Step.ErrNetworkDown);
      } else {
        setStep(Step.ErrNetworkDown);
      }
    }
  }

  function isDriverBasicComplete(): boolean {
    return !!phone && !!gender && !!dob;
  }

  function isDriverLicenseComplete(): boolean {
    return !!licenseNumber && !!badgeNumber && !!licenseExpiration
  }

  function isDriverBankComplete(): boolean {
    return !!bankAccountNumber && !!bankAccountName && !!bankSortCode
  }

  function isDriverDocumentComplete(): boolean {
    return !!driverPassportFiles.length && !!driverLicenseFrontFiles.length && !!driverLicenseBackFiles.length && !!driverBadgeFiles.length;
  }

  function isDriverComplete(): boolean {
    return isDriverBasicComplete() && isDriverLicenseComplete() && isDriverBankComplete() && isDriverDocumentComplete();
    // return isDriverBasicComplete() && isDriverLicenseComplete() && isDriverBankComplete(); // DEBUG
  }

  function isVehicleProfileComplete(): boolean {
    return !!vehicleRegistrationNumber && !!vehiclePlateNumber && !!vehiclePlateExpiration && !!vehicleYear && !!vehicleMake && !!vehicleModel && !!vehicleColor;
  }

  function isVehicleDocumentComplete(): boolean {
    return true;
  }

  function isVehicleComplete(): boolean {
    return (!vehicleRegistrationNumber) || (isVehicleProfileComplete() && isVehicleDocumentComplete());
  }

  function getVehicleDocumentRequirements(): VehicleDocument[] {
    if (!vehicleOwnership || !vehicleType)
      return [];
    return VehicleDocuments[vehicleOwnership][vehicleType];
  }

  function scrollToTop() {
    if (topRef.current)
      topRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <section ref={ topRef } className="section-yellow home-section book-section" id="top">

      <div className="container">

        <div className="row">
          <div className="col-md-6 col-sm-12 margin-top-40">
            <div className="intro">
              <div className="intro-text">
                <div className="text-center margin-bottom-20">
                  <img className="margin-bottom-20" src={imgLogo} />
                  <h1>Driver Enrollment</h1>
                </div>
                <p className="intro-invite">Join Driver 8's Cutting-Edge Driver Platform!</p>
                <p className="intro-invite-small">Ready to elevate your driving journey with us? We're thrilled to have you onboard.</p>
                <ul>
                  <li><b>Step 1:</b> Fill out the enrollment form below for both yourself and your vehicle.</li>
                  <li><b>Step 2:</b> Our team will swiftly review your submission.</li>
                  <li><b>Step 3:</b> Once approved, await an SMS containing a special link to download our state-of-the-art app.</li>
                </ul>
              </div>
            </div>
            <div className="intro margin-top-20">
              <div className="intro-text">
                <h1>Documentation</h1>

                <p className="intro-info">Before you start the enrollment form, please ensure you have the following:</p>
                <ul className="intro-info">
                  <li><b>All Necessary Documents:</b> Gather every document required for this process.</li>
                  <li><b>Color & Clarity:</b> Each document should be in color and of high resolution to ensure legibility.</li>
                  <li><b>No Photocopies:</b> Original scans or clear photos of the original documents are preferred. Avoid submitting black-and-white photocopies.</li>
                  <li><b>Avoid Cropping:</b> Ensure that all edges and corners of your documents are visible. Do not crop out any part of them.</li>
                  <li><b>Orientation:</b> Make sure your documents are upright. If any are sideways or upside down, please correct their orientation before uploading.</li>
                </ul>
                <p className="intro-info">Once your documents are ready and meet the above criteria, proceed with the form. This will expedite the review process and get you on the road faster!</p>

                <h4>Driver Documentation</h4>
                <ul>
                  <li>Passport (First 2 Pages)</li>
                  <li>Driver License Front and Back</li>
                  <li>Badge Number</li>
                </ul>
                <h4>Vehicle Documentation</h4>
                <table className="table table-bordered table-responsive-sm">
                  <thead>
                    <tr style={{ backgroundColor: '#1c3c43', fontWeight: 'bold' }}>
                      <td colSpan={2} align="center">Driver 8 Vehicle</td>
                      <td colSpan={2} align="center">Own Vehicle</td>
                    </tr>
                    <tr style={{ backgroundColor: '#21474f', fontWeight: 'bold' }}>
                      <td>Black Cab</td>
                      <td>Private Hire Vehicle</td>
                      <td>Black Cab</td>
                      <td>Private Hire Vehicle</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Log Book</td>
                      <td>Log Book</td>
                      <td>Registration Number</td>
                      <td>Log book</td>
                    </tr>
                    <tr>
                      <td>Cab License</td>
                      <td>Cab License</td>
                      <td></td>
                      <td>Cab License</td>
                    </tr>
                    <tr>
                      <td>Insurance</td>
                      <td>Insurance</td>
                      <td></td>
                      <td>Insurance</td>
                    </tr>
                    <tr>
                      <td>MoT</td>
                      <td>MoT</td>
                      <td></td>
                      <td>MoT</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {step === Step.Driver && (

            <form id="register-form"
              className="col-md-6 col-sm-12 margin-top-40 register-form register"
              onSubmit={ proceedToVehicle }>

              <div className={`form-field ${isDriverBasicComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>DRIVER PROFILE</h3>
                  <div className="row">
                    <div className="col-md-4">
                      <FormUpload
                        cropMode={true}
                        label="Picture"
                        description="Upload a picture of yourself"
                        name="newPicFile"
                        originalName="picFile"
                        required={true}
                        tabIndex={0}
                        aspectRatio={"square"}
                      />
                    </div>
                    <div className="col-md-8">
                      <PhoneInput
                        placeholder="Phone number"
                        value={phone}
                        onChange={p => p && isValidPhoneNumber(p) ? setPhone(p) : null}
                        id="phone"
                        required
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        defaultCountry="GB"
                        countries={["GB"]}
                        international={true}
                      />
                      <input placeholder="First Name" value={firstName} type="text" className="register-input white-input" onChange={(e: any) => setFirstName(e.target.value)} />
                      <input placeholder="Last Name" value={lastName} type="text" className="register-input white-input" onChange={(e: any) => setLastName(e.target.value)} />
                      <select value={gender} className="register-input white-input"
                        onChange={(e) => setGender(e.target.value as Gender)}>
                        <option value={Gender.Male}>Male</option>
                        <option value={Gender.Female}>Female</option>
                        <option value={Gender.Other}>Other</option>
                      </select>
                      <input placeholder="Date of Birth" value={dob} type="date" className="register-input white-input" onChange={(e: any) => setDob(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className={`form-field ${isDriverLicenseComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>LICENSE</h3>
                  <InputMask placeholder="License Number (16 Characters)" mask="XXXXX___________" replacement={{ _: /\d/, 'X': /[A-Za-z]/ }} className="register-input white-input" onChange={(e: any) => setLicenseNumber(e.target.value)} />
                  <InputMask placeholder="Badge Number (6 Digits)" mask="______" replacement={{ _: /\d/ }} value={badgeNumber} type="text" className="register-input white-input" onChange={(e: any) => setBadgeNumber(e.target.value)} />
                  <input placeholder="License Expiration" value={licenseExpiration} type="date" className="register-input white-input" onChange={(e: any) => setLicenseExpiration(e.target.value)} />
                </div>
              </div>

              <div className={`form-field ${isDriverBankComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>BANK DETAILS</h3>
                  <input placeholder="Name on Account" value={bankAccountName} className="register-input white-input" onChange={(e: any) => setBankAccountName(e.target.value)} />
                  <InputMask placeholder="Account Number (8 digits)" value={bankAccountNumber} mask="________" replacement={{ _: /\d/ }} className="register-input white-input" onChange={(e: any) => setBankAccountNumber(e.target.value)} />
                  <InputMask placeholder="Sort Code (6 digits)" value={bankSortCode} mask="__-__-__" replacement={{ _: /\d/ }} className="register-input white-input" onChange={(e: any) => setBankSortCode(e.target.value)} />
                </div>
              </div>

              <div className={`form-field ${isDriverDocumentComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>DOCUMENTS</h3>
                  <FormUpload
                    label="Passport"
                    name="newPassportFile"
                    originalName="passportFile"
                    required={true}
                    onUpload={setDriverPassportFiles}
                  />
                  <FormUpload
                    label="Driver License (Front)"
                    name="newLicenseFile"
                    originalName="licenseFile"
                    required={true}
                    onUpload={setDriverLicenseFrontFiles}
                  />
                  <FormUpload
                    label="Driver License (Back)"
                    name="newLicenseBackFile"
                    originalName="licenseBackFile"
                    required={true}
                    onUpload={setDriverLicenseBackFiles}
                  />
                  <FormUpload
                    label="Badge Number"
                    name="newBadgeFile"
                    originalName="badgeFile"
                    required={true}
                    onUpload={setDriverBadgeFiles}
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={!isDriverComplete()}
                className="register-submit margin-bottom-40"
                style={!isDriverComplete() ? { backgroundColor: "#858585" } : {}}
                onClick={ proceedToVehicle }
              >Proceed</button>

            </form>

          )}

          {step === Step.Vehicle && (

            <form id="register-form" className="col-md-6  margin-top-40 register-form register" method="post" onSubmit={completeEnrollment}>

              <div className={`form-field ${isVehicleProfileComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>VEHICLE PROFILE</h3>
                  <div className="register-input-group">
                    <input placeholder="Year" type="numeric" className="col-md-2 register-input white-input" onChange={(e: any) => setVehicleYear(e.target.value)} />
                    <input placeholder="Make" type="text" className="col-md-4 register-input white-input" onChange={(e: any) => setVehicleMake(e.target.value)} />
                    <input placeholder="Model" type="text" className="col-md-4 register-input white-input" onChange={(e: any) => setVehicleModel(e.target.value)} />
                    <input placeholder="Color" type="text" className="col-md-2 register-input white-input" onChange={(e: any) => setVehicleColor(e.target.value)} />
                  </div>
                  <input placeholder="VIN Number" type="text" className="register-input white-input" onChange={(e: any) => setVehicleVin(e.target.value)} />
                  <input placeholder="Registration Number" type="text" className="register-input white-input" onChange={(e: any) => setVehicleRegistrationNumber(e.target.value)} />
                  <input placeholder="Plate Number" type="text" className="register-input white-input" onChange={(e: any) => setVehiclePlateNumber(e.target.value)} />
                  <input placeholder="Plate Expiration" type="date" className="register-input white-input" onChange={(e: any) => setVehiclePlateExpiration(e.target.value)} />
                  <select value={vehicleOwnership || undefined} className="register-input white-input"
                    onChange={(e) => setVehicleOwnership(e.target.value as VehicleOwnership)}>
                    <option>Vehicle Ownership</option>
                    <option value={VehicleOwnership.Driver8}>Driver 8</option>
                    <option value={VehicleOwnership.Own}>Own</option>
                  </select>
                  <select value={vehicleType || undefined} className="register-input white-input"
                    onChange={(e) => setVehicleType(e.target.value as VehicleType)}>
                    <option>Vehicle Type</option>
                    <option value={VehicleType.BlackCab}>Black Cab</option>
                    <option value={VehicleType.PrivateHireVehicle}>Private Hire Vehicle</option>
                  </select>
                </div>
              </div>

              <div className={`form-field ${isVehicleDocumentComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>DOCUMENTS</h3>
                  { getVehicleDocumentRequirements().map(vehicleDocument => (
                    <FormUpload
                      label={ vehicleDocument}
                      name={ `new${vehicleDocument}File` }
                      originalName={`${vehicleDocument}File`}
                      required={true}
                    />
                  ))}
                  { getVehicleDocumentRequirements().length === 0 && (
                    <p>Select vehicle ownership and type to see required documents</p>
                  )}
                </div>
              </div>

              <button
                type="submit"
                disabled={!isVehicleComplete()}
                className="register-submit margin-bottom-40"
                style={!isVehicleComplete() ? { backgroundColor: "#858585" } : {}}
                onClick={ completeEnrollment }
              >Complete Enrollment</button>

            </form>

          )}

          { step === Step.Submitting && (
            <div className="intro intro-loading col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Submitting</h1>
              <p className="mb-0">Please wait while we submit your enrollment details...</p>
            </div>
            </div>
          )}

          { step === Step.Uploading && (
            <div className="intro intro-loading col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Uploading documents</h1>
              <p className="mb-0">Please wait while we upload your documents...</p>
            </div>
            </div>
          )}

          { step === Step.Submitted && (
            <div className="intro intro-success col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Enrollment Complete</h1>
              <p>Your details have been saved with us. Please wait for our team to evaluate and approve your enrollment. You will receive an SMS upon approval.</p>
              <p className="margin-top-10">You can now close this window.</p>
            </div>
            </div>
          )}

          { step === Step.Loading && (
            <div className="intro intro-loading col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Loading</h1>
              <p className="mb-0">Please wait while we load your details...</p>
            </div>
            </div>
          )}

          { step === Step.ErrInvalidCode && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Invalid URL</h1>
              <p className="mb-0">Please ensure that the URL matches the one provided in the SMS.</p>
            </div>
            </div>
          )}
          
          { step === Step.ErrNetworkDown && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Network Error</h1>
              <p className="mb-0">Please ensure that your network is connected, or there might be an issue with the server.</p>
              <p className="mb-0">You can retry in a little while.</p>
            </div>
            </div>
          )}

          { step === Step.ErrServerError && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Server Error</h1>
              <p className="mb-0">We are currently down for maintenance.</p>
              <p className="mb-0">You can retry in a little while.</p>
            </div>
            </div>
          )}

          { step === Step.ErrGeneral && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
            <div className="intro-text">
              <h1 className="alert-heading">Error</h1>
              <p className="mb-0">{ error }</p>
            </div>
            </div>
          )}

        </div>

      </div>

    </section>
  )

}