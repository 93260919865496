import Axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from 'react-phone-number-input';
import { Link, useLocation } from 'react-router-dom';

const imgLogo = require('../../img/driver8-logo.svg');
const imgPlayStore = require('../../img/play-store.png');
const imgAppStore = require('../../img/app-store.png');

enum Step {
  Welcome,
  ErrInvalidCode,
}

const BASE_URL = process.env.REACT_APP_API_URL;

export function PageWelcome() {

  useEffect(() => {
    document.title = 'Driver 8 | Welcome';
  }, []);

  const location = useLocation();

  const [code, setCode] = useState<string | null>(null);
  const [codeError, setCodeError] = useState<string>();
  const [step, setStep] = useState<Step>(Step.Welcome);
  const [phone, setPhone] = useState<string>();

  useEffect(() => {
    const urlParams = parseUrlSearchParams(location.search);
    if (!urlParams.code || !isCodeFormatCorrect(urlParams.code)) {
      // setStep(Step.ErrInvalidCode);
      // return;
    }

    setCode(urlParams.code);

  }, []);

  async function getCode() {
    try {
      const response = await Axios.get(`${BASE_URL}/driver/enroll/phone/${phone}`);
      setCode(response.data);
      setCodeError(undefined);
    } catch (err: AxiosError | any) {
      setCode('NEW');
      // setCodeError('Invalid phone number')
    }
  }

  function parseUrlSearchParams(params: string): { code: string } {
    const urlParams = new URLSearchParams(params);
    return {
      code: urlParams.get('code') || '',
    };
  }

  // Code format needs to be in the format AAA-NNNN
  function isCodeFormatCorrect(code: string): boolean {
    return /^([A-Z]{2})([0-9]{4})$/.test(code);
  }
  return (
    <section className="section-yellow home-section book-section" id="top">

      <div className="container">

        <div className="row">
          <div className="welcome col-md-6 col-sm-12 welcome-container">
            <img src={imgLogo} />
            <h1>Welcome to Driver 8</h1>
            <a href="https://play.google.com/store/apps/details?id=com.driver8.driver" target="_blank">
              <img src={imgPlayStore} width={250} />
            </a>
            <a href="https://apps.apple.com/gb/app/driver-8-meter-earn-more/id6473653720" target="_blank">
              <img src={imgAppStore} width={200} />
            </a>
            {/* { step === Step.Welcome && (
            <>
              { code && code !== 'NEW' && (
                <>
                  <h2>You have been enrolled into Driver 8's new platform as a Driver</h2>
                  <h3><a href="#" target="_blank">
                    Download the Driver App:
                  </a></h3>
                  <a href="https://play.google.com/store/apps/details?id=com.driver8.driver" target="_blank">
                    <img src={imgPlayStore} width={250} />
                  </a>
                  <a href="https://apps.apple.com/gb/app/driver-8-meter-earn-more/id6473653720" target="_blank">
                    <img src={imgAppStore} width={200} />
                  </a>
                </>
              )}
              { code && code === 'NEW' && (
                <div className="col-md-12 col-sm-6">
                  <h4 style={{ color: '#d0d0d0' }}>You can signup as a driver with Driver 8</h4>
                  <Link to="/" className="register-submit">Signup</Link>
                </div>
              )}
              <div className="welcome-code">
                { code && code !== 'NEW' && (
                  <div className='row'>
                    <div className='col-md-6'>
                      <h4>Driver Code</h4>
                      <h5>{ code }</h5>
                    </div>
                    <div className='col-md-6'>
                      <h4>Vehicle Plate</h4>
                      <h5>0000</h5>
                    </div>
                  </div>
                )}
                { !code && false && (
                  <>
                    <p>Enter your phone number to view your driver code</p>
                    <p>Use the driver code and your vehicle registration number to login to the app</p>
                    { codeError && <div className="alert alert-danger">{ codeError }</div> }
                    <div className="row">
                      <PhoneInputWithCountrySelect
                        placeholder="Phone number"
                        value={phone}
                        onChange={p => p && isValidPhoneNumber(p) ? setPhone(p) : null}
                        id="phone"
                        required
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        defaultCountry="GB"
                        countries={["GB"]}
                        international={true}
                        className='col-md-8 col-sm-12'
                      />
                      <button className="register-submit col-md-4 col-sm-12" onClick={getCode}>Get Code</button>
                    </div>
                  </>
                )}
              </div>
            </>
            )} */}
          </div>
          {step === Step.Welcome && (<div className="col-md-6 col-sm-12 welcome-container">
            <div className="intro">
              <div className="intro-text">
                <h1>GETTING STARTED</h1>
                <p className="intro-invite">Join Driver 8's Cutting-Edge Driver Platform!</p>
                <p className="intro-invite-small">Ready to elevate your driving journey with us? We're thrilled to have you onboard.</p>
                <ul>
                  <li><b>Step 1:</b> Download the app using the link provided.</li>
                  <li><b>Step 2:</b> Login using your Phone Number. You will be sent an SMS Verification Code that you need to enter to login..</li>
                  <li><b>Step 3:</b> Once you're logged in, you are ready to start operating with Driver 8.</li>
                </ul>
              </div>
            </div>
          </div>
          )}

        </div>

      </div>

    </section>
  )

}