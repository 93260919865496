import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Driver } from "../../component/entities/driver.entity";
import { Vehicle } from "../../component/entities/vehicle.entity";
import Axios, { AxiosError } from "axios";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { Agreement } from "./pdf";
import SignatureCanvas from 'react-signature-canvas'
import FileSaver from 'file-saver';
import { BrowserView, MobileView } from 'react-device-detect';


const BASE_URL = process.env.REACT_APP_API_URL;

enum Step {
  Loading,
  View,
  Submitting,
  Submitted,

  ErrNetworkDown,
  ErrInvalidCode,
  ErrServerError,
  ErrGeneral,
}

interface Agreement {
  id: string;
  driver: Driver;
  vehicle: Vehicle;
  weeklyAmount: number;
}

export function PageRentalSign() {
  useEffect(() => {
    document.title = 'Driver 8 | Taxi Rental Agreement';
  }, []);

  const location = useLocation();

  const signatureRef = useRef<SignatureCanvas>(null);
  const [agreementId, setAgreementId] = useState<string | null>(null);
  const [agreement, setAgreement] = useState<Agreement>();
  const [showSignPad, setShowSignPad] = useState(false);
  const [showSignHint, setShowSignHint] = useState(true);
  const [signature, setSignature] = useState<string>();
  const [step, setStep] = useState<Step>(Step.Loading);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = parseUrlSearchParams(location.search);
    if (!urlParams.id) {
      setStep(Step.ErrInvalidCode);
      return;
    }

    setAgreementId(urlParams.id);
    loadDetails(urlParams.id);

  }, []);

  async function loadDetails(id: string) {
    try {
      const response = await Axios.get(`${BASE_URL}/driver/enroll/rental/${id}`);
      setAgreement(response.data);
      setStep(Step.View);
    } catch (err: AxiosError | any) {
      if (err.response?.status === 400) {
        setError(err.response.data.message);
        setStep(Step.ErrGeneral);
      } else if (err.response?.status === 404) {
        setStep(Step.ErrInvalidCode);
      } else if (err.response?.status > 400) {
        setStep(Step.ErrServerError);
      } else if (err.code === 'ECONNABORTED') {
        setStep(Step.ErrNetworkDown);
      } else {
        setStep(Step.ErrNetworkDown);
      }
    }
  }

  function sign() {
    setShowSignPad(true);
  }

  async function submit() {
    if (!agreement) {
      return;
    }
    
    setStep(Step.Submitting);

    const blob = await pdf(
      <Agreement
        driver={agreement.driver}
        vehicle={agreement.vehicle}
        amount={agreement.weeklyAmount}
        signature={signature}
      />
    ).toBlob();

    const data = new FormData();
    data.append('id', agreement.driver.id as string);
    data.append('agreement', blob, `${agreement.id}.pdf`);
  
    await Axios.post(`${BASE_URL}/driver/enroll/rental-sign`, { id: agreement.id });
    await Axios.post(`${BASE_URL}/driver/register/upload`, data);

    FileSaver.saveAs(blob, 'Driver 8 Taxi Rental Agreement.pdf');

    setStep(Step.Submitted);
  }

  async function download() {
    if (!agreement) {
      return;
    }

    const blob = await pdf(
      <Agreement
        driver={agreement.driver}
        vehicle={agreement.vehicle}
        amount={agreement.weeklyAmount}
        signature={signature}
      />
    ).toBlob();

    FileSaver.saveAs(blob, 'Driver 8 Taxi Rental Agreement.pdf');
  }

function parseUrlSearchParams(params: string): { id: string } {
  const urlParams = new URLSearchParams(params);
  return {
    id: urlParams.get('id') || '',
  };
}

return (
  <section className="text-center row" id="top">

    {step === Step.Submitted && (
      <div className="intro intro-success col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Agreement Complete</h1>
          <p>Your agreement has been successfully signed and approved.</p>
          <p className="margin-top-10">You can now close this window.</p>
        </div>
      </div>
    )}

    {step === Step.Submitting && (
      <div className="intro intro-loading col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Submitting...</h1>
          <p>Please wait while we save your agreement</p>
        </div>
      </div>
    )}

    {step === Step.Loading && (
      <div className="intro intro-loading col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Loading</h1>
          <p className="mb-0">Please wait while we load your details...</p>
        </div>
      </div>
    )}

    {step === Step.ErrInvalidCode && (
      <div className="intro intro-error col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Invalid URL</h1>
          <p className="mb-0">Please ensure that the URL matches the one provided in the SMS.</p>
        </div>
      </div>
    )}

    {step === Step.ErrNetworkDown && (
      <div className="intro intro-error col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Network Error</h1>
          <p className="mb-0">Please ensure that your network is connected, or there might be an issue with the server.</p>
          <p className="mb-0">You can retry in a little while.</p>
        </div>
      </div>
    )}

    {step === Step.ErrServerError && (
      <div className="intro intro-error col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Server Error</h1>
          <p className="mb-0">We are currently down for maintenance.</p>
          <p className="mb-0">You can retry in a little while.</p>
        </div>
      </div>
    )}

    {step === Step.ErrGeneral && (
      <div className="intro intro-error col-md-3 col-sm-12 margin-top-40">
        <div className="intro-text">
          <h1 className="alert-heading">Error</h1>
          <p className="mb-0">{error}</p>
        </div>
      </div>
    )}

    {step === Step.View && agreement && (
      <>
        <div className="col-md-3 col-sm-12 padding-40">
          <div className="sign-container intro-text">
            <h1>Taxi Rental Agreement</h1>
            {!showSignPad &&
              <>
                <p className="mb-0">Please review the agreement.</p>
                <p>Once you're ready to sign it, click the button below to digitally sign.</p>
                <p>After signing, your driver account will be fully activated.</p>
                <p>A copy of the signed agreement will be downloaded here</p>
                <button
                  type="submit"
                  className="register-submit margin-top-20"
                  onClick={sign}
                >Sign Agreement</button>
                <MobileView>
                  <button
                    type="button"
                    className="register-submit margin-top-20"
                    onClick={download}
                  >Download Agreement PDF</button>
                </MobileView>
              </>
            }
            {showSignPad && (
              <>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor='blue'
                  canvasProps={{ width: 250, height: 200, className: 'sign-pad' }}
                  onBegin={() => setShowSignHint(false)}
                  onEnd={() => setSignature(signatureRef.current?.toDataURL())}
                />
                {showSignHint && <p className="sign-pad-hint">Sign Here</p>}
                <p>By submitting your signature, you agree by the terms of the agreement.</p>
                <button
                  type="submit"
                  className="register-submit margin-top-20"
                  onClick={submit}
                >Submit Signature</button>
              </>
            )}
          </div>
        </div>
        <BrowserView className="col-md-9 col-sm-12">
          <PDFViewer width={'100%'} height={window.innerHeight}>
            <Agreement
              driver={agreement.driver}
              vehicle={agreement.vehicle}
              amount={agreement.weeklyAmount}
              signature={signature}
            />
          </PDFViewer>
        </BrowserView>
      </>
    )}

  </section>
)
}