/// <reference types="googlemaps" />
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { InputMask } from '@react-input/mask';
import Axios, { AxiosError } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from 'react-phone-number-input';

const imgLogo = require('../../img/driver8-logo.svg');

enum Step {
  Form,
  Submitting,
  Submitted,

  ErrNetworkDown,
  ErrServerError,
  ErrGeneral,
}

const MAP_LIBS: Libraries = ['places'];
const BASE_URL = process.env.REACT_APP_API_URL;

interface location {
  lat: number | undefined,
  lng: number | undefined,
  text: string | undefined,
  area: string | undefined,
  city: string | undefined,
  state: string | undefined,
  country: string | undefined,
}

export function PageRentalForm() {

  let addressAutocompleteRef = useRef<Autocomplete>(null);

  useEffect(() => {
    document.title = 'Driver 8 | Taxi Rental Enrollment';
  }, []);

  const onLoad = useCallback(() => {
    if (addressAutocompleteRef.current?.state?.autocomplete) {
      addressAutocompleteRef.current.state.autocomplete.addListener('place_changed', () => onAddressSelect());
    }
  }, []);
  
  const [step, setStep] = useState<Step>(Step.Form);

  const [error, setError] = useState<string | null>(null);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [address, setAddress] = useState<location>();
  const [licenseNumber, setLicenseNumber] = useState<string>();
  const [licenseExpiryDate, setLicenseExpiryDate] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();

  const topRef = useRef<HTMLElement>(null);

  async function save() {
    const data = {
      firstName,
      lastName,
      phone,
      address: {
        lat: address?.lat,
        lng: address?.lng,
        text: address?.text,
        area: address?.area,
        city: address?.city,
        state: address?.state,
        country: address?.country,
      },
      dateOfBirth,
      licenseNumber,
      licenseExpiryDate,
    }

    await Axios.post(`${BASE_URL}/driver/enroll/rental`, data);
  }

  async function submit(e: any) {
    setStep(Step.Submitting);
    e.preventDefault();
    scrollToTop();

    try {
      await save()
      setStep(Step.Submitted);
    } catch (err: AxiosError | any) {
      if (err.response?.status === 400) {
        setError(err.response.data.message);
        setStep(Step.ErrGeneral);
      } else if (err.response?.status > 400) {
        setStep(Step.ErrServerError);
      } else if (err.code === 'ECONNABORTED') {
        setStep(Step.ErrNetworkDown);
      } else {
        setStep(Step.ErrNetworkDown);
      }
    }
  }

  function onAddressSelect() {
    const areaTypes = ['locality', 'sublocality_level_1', 'sublocality', 'political'];
    const autocomplete: google.maps.places.Autocomplete | null | undefined = addressAutocompleteRef.current?.state.autocomplete;

    if (!autocomplete) {
      return
    }
    const place = autocomplete.getPlace();

    if (!place) {
      setAddress(undefined);
      return;
    }

    let city, state, country;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const area = place.address_components?.find((component: any) => {
         for (let areaType of areaTypes) {
            if (component.types.includes(areaType)) {
               return true;
            }
         }
         return false;
      });


      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      place.address_components?.forEach((component: any) => {
         if ((component?.types?.indexOf("locality") > -1)) {
            city = component.long_name;
         }

         if ((component.types.indexOf("postal_town") > -1)) {
            city = component.long_name;
         }

         if (component.types.indexOf("administrative_area_level_1") > -1) {
            state = component.short_name;
         }

         if (component.types.indexOf("country") > -1) {
            country = component.long_name;
         }
      });


      // let key: string = Object.keys(autocomplete..place)[0];

      setAddress({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        text: place.formatted_address,
        area: area?.long_name,
        city,
        country,
        state,
      });
  }

  function isProfileComplete(): boolean {
    console.log(phone, address, dateOfBirth)
    return !!firstName && !!lastName && !!phone && !!address && !!dateOfBirth;
  }
  function isLicenseComplete(): boolean {
    return !!licenseNumber && !!licenseExpiryDate;
  }

  function isFormComplete(): boolean {
    return isProfileComplete() && isLicenseComplete();
  }

  function scrollToTop() {
    if (topRef.current)
      topRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
    <section ref={topRef} className="section-yellow home-section book-section" id="top">

      <div className="container">

        <div className="row">
          
          <div className="col-md-6 col-sm-12 margin-top-40">
          <div className="welcome padding-bottom-20 margin-bottom-20">
            <img src={imgLogo} />
            <h1>Welcome to Driver 8</h1>
          </div>
            <div className="intro">
              <div className="intro-text">
                <h1>Taxi Rental Enrollment</h1>
                <p className="intro-invite">Join Driver 8's Cutting-Edge Driver Platform!</p>
                <p className="intro-invite-small">Ready to elevate your driving journey with us? We're thrilled to have you onboard.</p>
                <ul>
                  <li><b>Step 1:</b> Fill out the enrollment form below.</li>
                  <li><b>Step 2:</b> Our team will swiftly review your submission.</li>
                  <li><b>Step 3:</b> Once approved, await an SMS containing a link to a digitial agreement. Sign the agreement and download our state-of-the-art app.</li>
                </ul>
              </div>
            </div>
          </div>

          {step === Step.Form && (

            <form id="register-form"
              className="col-md-6 col-sm-12 margin-top-40 register-form register"
              onSubmit={submit}>

              <div className={`form-field ${isProfileComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>DRIVER PROFILE</h3>
                  <div className="row">
                    <PhoneInputWithCountrySelect
                      placeholder="Phone number"
                      value={phone}
                      onChange={p => p && isValidPhoneNumber(p) ? setPhone(p) : null}
                      id="phone"
                      required
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      defaultCountry="GB"
                      countries={["GB"]}
                      international={true}
                      className='col-sm-12'
                    />
                    <input placeholder="First Name" value={firstName} type="text" className="register-input white-input" onChange={(e: any) => setFirstName(e.target.value)} />
                    <input placeholder="Last Name" value={lastName} type="text" className="register-input white-input" onChange={(e: any) => setLastName(e.target.value)} />
                    <input placeholder="Date of Birth" value={dateOfBirth} type="date" className="register-input white-input" onChange={(e: any) => setDateOfBirth(e.target.value)} />
                    {/* <input placeholder="Address" value={address} type="address" className="register-input white-input" onChange={(e: any) => setAddress(e.target.value)} /> */}
                    <Autocomplete
                        ref={addressAutocompleteRef}
                        className="col-sm-12 autocomplete-container"
                        fields={['geometry', "address_component", "formatted_address"]}
                        restrictions={{ country: ['gb'] }}
                        onLoad={onLoad}>
                        <input className="register-input white-input" autoFocus required placeholder="Address" type="search" />
                    </Autocomplete>
                  </div>
                </div>
              </div>

              <div className={`form-field ${isLicenseComplete() ? 'checked' : ''}`}>
                <div>
                  <h3>DRIVING LICENSE</h3>
                  <InputMask placeholder="Driving License Number (18 Characters)" mask="____ ....... | _.__ .." replacement={{ '.': /\d/, '_': /[A-Za-z]/ }} className="register-input white-input" onChange={(e: any) => setLicenseNumber(e.target.value)} />
                  <input placeholder="Driving License Expiration" value={licenseExpiryDate} type="date" className="register-input white-input" onChange={(e: any) => setLicenseExpiryDate(e.target.value)} />
                </div>
              </div>

              <button
                type="submit"
                disabled={!isFormComplete()}
                className="register-submit margin-bottom-40"
                style={!isFormComplete() ? { backgroundColor: "#858585" } : {}}
                onClick={submit}
              >Complete Enrollment</button>

            </form>

          )}

          {step === Step.Submitting && (
            <div className="intro intro-loading col-md-6 col-sm-12 margin-top-40">
              <div className="intro-text">
                <h1 className="alert-heading">Submitting</h1>
                <p className="mb-0">Please wait while we submit your enrollment details...</p>
              </div>
            </div>
          )}

          {step === Step.Submitted && (
            <div className="intro intro-success col-md-6 col-sm-12 margin-top-40">
              <div className="intro-text">
                <h1 className="alert-heading">Enrollment Complete</h1>
                <p>Your details have been saved with us. Please wait for our team to evaluate and approve your enrollment. You will receive an SMS upon approval.</p>
                <p className="margin-top-10">You can now close this window.</p>
              </div>
            </div>
          )}

          {step === Step.ErrNetworkDown && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
              <div className="intro-text">
                <h1 className="alert-heading">Network Error</h1>
                <p className="mb-0">Please ensure that your network is connected, or there might be an issue with the server.</p>
                <p className="mb-0">You can retry in a little while.</p>
              </div>
            </div>
          )}

          {step === Step.ErrServerError && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
              <div className="intro-text">
                <h1 className="alert-heading">Server Error</h1>
                <p className="mb-0">We are currently down for maintenance.</p>
                <p className="mb-0">You can retry in a little while.</p>
              </div>
            </div>
          )}

          {step === Step.ErrGeneral && (
            <div className="intro intro-error col-md-6 col-sm-12 margin-top-40">
              <div className="intro-text">
                <h1 className="alert-heading">Error</h1>
                <p className="mb-0">{error}</p>
              </div>
            </div>
          )}

        </div>

      </div>

    </section>
    </LoadScript>
  )

}