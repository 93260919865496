import React, { useEffect } from 'react';
export function PageNotFound() {

    useEffect(() => {
        document.title = 'Driver 8 | Page Not Found';
    }, []);

    return (
        <section style={{ marginTop: 100 }}>
            <h2 className="text-center">Page Not Found!</h2>
            <p className="text-center">Sorry, the page you requested could not be found.</p>
        </section>
    )
} 