import { IEntityBase, IEntityCreatedBy, IEntityTimestamp } from "../interfaces/entity.interface";
import { Feature } from "./feature.entity";
import { IFile } from "./file.entity";
import { Fleet } from "./fleet.entity";
import { Group } from "./group.entity";
import { Network } from "./network.entity";
import { Operator } from "./operator.entity";
import { Address } from "./address.entity";
import { Inspection } from "./inspection.entity";
import { Consent } from "./consent.entity";
import { EnumDeclaration } from "typescript";

export enum DriverStatus {
  New = 'new',
  PendingApproval = 'pending-approval',
  PendingData = 'pending-data',
  Failed = 'failed',
  Active = 'active',
  Blocked = 'blocked',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export enum Activity {
  Online = 'online',
  Offline = 'offline',
  OnTrip = 'on-trip',
}

export enum DriverFileType {
  Pic = 'pic',
  License = 'license',
  Identity = 'identity',
  Police = 'police',
  History = 'history',
  Insurance = 'insurance',
  Medicare = "medicare",
  Vevo = "vevo",
  Proficiency = "proficiency"
}

export interface Driver
  extends IEntityBase,
  IEntityTimestamp,
  IEntityCreatedBy {
  [key: string]: any;
  code: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  email: string;
  phone: string;
  dateOfBirth: Date | string;
  address?: Address;
  businessRegistration?: string;
  features: Feature[];
  fleets: Fleet[];
  networks: Network[];
  consent: Consent;
  operator: Operator;
  groups?: Group[];
  inspection: Inspection[];
  licenseNumber: string;
  licenseType: string;
  licenseExpiryDate: Date | string;
  emergencyName?: string;
  emergencyEmail?: string;
  emergencyPhone?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankAccountRouting?: string;
  bankAccountTitle?: string;

  activity?: Activity;

  picFile?: IFile;
  newPicFile?: File;

  licenseFiles?: IFile[];
  newLicenseFiles?: File[];

  identityFiles?: IFile[];
  newIdentityFiles?: File[];

  policeFiles?: IFile[];
  newPoliceFiles?: File[];

  historyFiles?: IFile[];
  newHistoryFiles?: File[];

  insuranceFiles?: IFile[];
  newInsuranceFiles?: File[];

  vevoFiles?: IFile[];
  newVevoFiles?: File[];

  medicareCardFiles?: IFile[];
  newMedicareCardFiles?: File[];

  englishLanguageProficiencyFiles?: IFile[];
  newEnglishLanguageProficiencyFiles?: File[];

  status?: DriverStatus;
}
