import React from "react";
import ReactDOM from "react-dom";
import 'react-phone-number-input/style.css';
import {
    Route,
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import { PublicRoute } from "./component/routes/publicRoute";
import { ScrollToTop } from "./component/scroll/Scroll";
import './css/animate.css';
import './css/style.css';
import { PageNotFound } from './page/not-found/not-found';
import { PageRegister } from "./page/register/register";
import { PageRentalForm } from "./page/rental/form";
import { PageWelcome } from "./page/welcome/welcome";
import * as serviceWorker from './serviceWorker';
import { PageRentalSign } from "./page/rental/sign";

export default function App() {
    return (
        <div className="appWrapper">
            <Router>
                <>
                    <ScrollToTop />
                    <Switch>
                        <PublicRoute exact path='/' component={ PageRegister } />
                        <PublicRoute exact path='/welcome' component={ PageWelcome } />
                        <PublicRoute exact path='/rental' component={ PageRentalForm } />
                        <PublicRoute exact path='/rental/sign' component={ PageRentalSign } />
                        <Route exact path='*' component={ PageNotFound } />
                    </Switch>
                </>
            </Router>
        </div>
    );
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

serviceWorker.unregister();
